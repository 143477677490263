import "./App.css";
import axios from "axios";
import React, { useRef, useEffect, useState } from "react";
import { Carousel } from "antd";

import downSvg from "./assets/down.svg";
import gooleSvg from "./assets/goole.svg";
import downloadSvg from "./assets/download.svg";
import { eventParams ,generateRandomString} from "./util/tools";
const card2 = "https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-20_imagewebp?alt=media&token=43b1a1e7-8c83-4cb5-b9d1-78a4db24a450";
const card4 = "https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-22_imagewebp?alt=media&token=844c5b76-8fd9-4893-8a36-2e0a1b0c77d6";
const card3 = "https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-21_imagewebp?alt=media&token=6a785a9b-daad-4f12-ac6a-074e204d47ba";
const card5 = "https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-23_imagewebp?alt=media&token=d20fbfa8-57ee-4f95-8405-d2e83d36c5e7";

const card1 = "https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-19_imagewebp?alt=media&token=fa5842ef-6705-4284-b5c9-c13e1fff7105";
const App = () => {
  const [showMask, setShowMask] = useState(false);
  const params = eventParams()
  const pixelId = "1791929828215850";
  const ft = useRef(
    "EAA0UV0MrCb0BO5UXplPNK1mG8FY3zQwOEyHdnVfbzJ0wnJ8YeULSjM7RfVpTujc4mwIbeZCm12ZBt8Vxzg9mfYVJzvrZCyOxTxAVI3ymaMOtfCGCfB5OOlBtfRC4AQD4B2yR91GKi1zGlmLNkUZBXXWDe1dhtwbA0jM82xnZCPBePvli26giZAixIb"
  );

  useEffect(() => {
    console.log("version1.2");

    //检测用户即将离开页面
    const handleBeforeUnload = () => {
      // eslint-disable-next-line no-undef
      fbq("trackCustom", "user logout");
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  async function trackClick(eventName) {
    // eslint-disable-next-line no-undef
    fbq("trackCustom", eventName);
    
    const eventData = {
      data: [
        {
          event_name: eventName,
          event_time: Math.floor(Date.now() / 1000),
          event_id: generateRandomString(10),
          app_data: {
            extinfo: params.extinfo, // 添加 event_id
          },
          user_data: {
            em: params.hashedEmail, // 哈希后的电子邮件
            ph: params.hashedPhone, // 哈希后的电话号码
            fn: params.hashedFirstName, // 哈希后的姓名
            ln: params.hashedLastName, // 哈希后的姓氏
            ct: params.hashedCity, // 哈希后的城市
            st: params.hashedState, // 哈希后的州
            zp: params.hashedZipCode, // 哈希后的邮政编码
            country: params.hashedCountry, // 哈希后的国家
          },
          action_source: "website",
        },
      ],
      access_token: ft.current,
    };

    try {
      await axios.post(
        `https://graph.facebook.com/v21.0/${pixelId}/events?access_token=${ft.current}`,
        eventData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    } catch (error) {
      console.error("Error uploading data:", error);
    }

    window.location.href = "https://hourse.onelink.me/g1Pu/dx6ic4k8";
  }

  const updateMask = (value) => {
    setShowMask(value);
  };

  return (
    <div className="app">
     {/* //card1 */}
     <div className="card1">
      <div className="logo">
        <img
          src={require("./assets/logo.webp")}
          alt=""
          style={{ width: "22px" }}
          loading="eager"
        />
        Meboo
      </div>
      <img
        src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-35_imagewebp?alt=media&token=520a2909-f41d-44b7-837c-f49588e72df2"}
        alt=""
        width={323}
        loading="eager"
      />

      <div className="over18">
        <div className="yearsold">Are you over 18 years old?</div>
        <div className="btn-container">
          <div className="no" onClick={() => updateMask(true)}>
            No
          </div>
          <div
            className="no yes"
            onClick={() => trackClick("Yes click through rate")}
          >
            Yes
          </div>
        </div>
        <img
          src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-33_imagesvg%2Bxml?alt=media&token=122b463a-d23e-4c89-b3f5-d92b5c1bf539"}
          alt=""
          width="78"
          className="mouth"
          loading="auto"
        />
      </div>
    </div>

      <div className="find">
        <img src={downSvg} alt="" width="19" loading="lazy" />
        <p>Find your best escort femme fatale</p>
        <img src={downSvg} alt="" width="19px" loading="lazy" />
      </div>

      <div className="card2">
        <Carousel
          autoplay
          autoplaySpeed={3000}
          dots={false}
          slidesToScroll={1}
          slidesToShow={1.5}
          swipe
        >
          {[card1, card2, card3, card4, card5].map((image, index) => (
            <div key={index} className="carousel-item">
              <img
                src={image}
                alt=""
                style={{ width: "100%" }}
                loading="lazy"
              />
              <div
                className="choose"
                style={{ width: "120px" }}
                onClick={() => trackClick("Card like Button Click Rate")}
              >
                Choose me
              </div>
            </div>
          ))}
        </Carousel>
      </div>

      <div className="card3">
        <div className="card3-top">
          <img
            src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-25_imagewebp?alt=media&token=da051483-ae07-45e5-a411-ae1d141ec6de"}
            alt=""
            width={"188"}
            loading="lazy"
            onClick={() => trackClick("Chat interface click through rate")}
          />
          <div className="chat-wrep">
            <div className="what">What Men Say</div>
            <div className="chatmess">
              <p className="mess">
                “This gives you a ‘don't give a sh*t’ attitude, you feel
                invincible, you can do anything you want!”
              </p>
              <hr style={{ margin: "10px 0 10px" }} />
              <p className="nickname">
                <img
                  src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-29_imagewebp?alt=media&token=efe5b82d-475d-4455-bec9-5d8b565a209f"}
                  alt=""
                  style={{ width: "14px" }}
                  loading="lazy"
                />
                Hamid
              </p>
            </div>

            <div className="chatmess">
              <p className="mess">
                “I haven’t kissed a girl in 14 years… and I was kissing a girl
                last night. It was crazy!”
              </p>
              <hr style={{ margin: "10px 0 10px" }} />
              <p className="nickname">
                <img
                  src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-30_imagewebp?alt=media&token=b9ae21e0-7be1-4617-a3a1-8859b843ff4c"}
                  alt=""
                  style={{ width: "14px" }}
                  loading="lazy"
                />
                Shahid
              </p>
            </div>

            <div className="chatmess">
              <p className="mess">
                “This is better than anything I could've read about. I
                approached more girls in one day than a whole damn year."
              </p>
              <hr style={{ margin: "10px 0 10px" }} />
              <p className="nickname">
                <img
                  src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-31_imagewebp?alt=media&token=fbcb3802-2f9b-4f2d-aa2e-72546125f046"}
                  alt=""
                  style={{ width: "14px" }}
                  loading="lazy"
                />
                Mike
              </p>
            </div>
          </div>
        </div>
        <div
          className="download"
          onClick={() => trackClick("Download Button Click Rate")}
        >
          <img
            src={gooleSvg}
            alt=""
            style={{
              marginRight: "7px",
              width: "21px",
            }}
            loading="lazy"
          />
          Download and start experiencing
          <img
            src={downloadSvg}
            alt=""
            style={{
              marginLeft: "12px",
              width: "16px",
            }}
            loading="lazy"
          />
        </div>
      </div>

      {showMask && (
        <div className="mask" v-if="showMask">
          <div className="rest">
            <div
              style={{ position: "absolute", top: "20px", right: "20px" }}
              onClick={() => updateMask(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <rect
                  width="2.82807"
                  height="19.7965"
                  rx="1.41403"
                  transform="matrix(0.707083 -0.707131 0.707083 0.707131 0 2)"
                  fill="white"
                />
                <rect
                  width="2.82807"
                  height="19.7965"
                  rx="1.41403"
                  transform="matrix(0.707082 0.707131 -0.707083 0.707131 14 0)"
                  fill="white"
                />
              </svg>
            </div>

            <img
              src={"https://firebasestorage.googleapis.com/v0/b/meboo-online-b68e7.appspot.com/o/images3%2Frc-upload-1731490473706-27_imagewebp?alt=media&token=4c51fc6d-6897-411d-905a-cf0a59fdf3a6"}
              alt=""
              width="160"
              loading="lazy"
            />
            <div className="explain">
              This page contains age-restricted content, please ensure you are
              18 or older.
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
