import SHA256 from "crypto-js/sha256";
import platform from "platform";

export const eventParams =()=>{
    // 对个人身份信息进行 SHA256 哈希处理
    const email = "example@example.com";
    const hashedEmail = SHA256(email.toLowerCase().trim()).toString();

    const phone = "1234567890";
    const hashedPhone = SHA256(phone.trim()).toString();

    const firstName = "John";
    const hashedFirstName = SHA256(firstName.trim()).toString();

    const lastName = "Doe";
    const hashedLastName = SHA256(lastName.trim()).toString();

    const city = "San Francisco";
    const hashedCity = SHA256(city.trim()).toString();

    const state = "CA";
    const hashedState = SHA256(state.trim()).toString();

    const zipCode = "94105";
    const hashedZipCode = SHA256(zipCode.trim()).toString();

    const country = "US";
    const hashedCountry = SHA256(country.trim()).toString();
    // eslint-disable-next-line
    const extinfo = [
        "", // 扩展信息版本 (iOS 为 i2, Android 为 a2)
        "hitchats.fun", // 应用项目包名称
        "", // 段版本
        "", // 长版本
        platform.os.version, // 操作系统版本
        platform.os.family, // 设备型号名称
        navigator.language || navigator.languages[0], // 区域设置
        "", // 时区缩写
        "", // 运营商
        "", // 屏幕宽度
        "", // 屏幕高度
        "", // 屏幕密度
        "", // CPU 核心数
        "", // 外部存储空间大小 (GB)
        "", // 剩余的外部存储空间 (GB)
        "", // 设备时区
      ];
    return {
        hashedEmail,
        hashedPhone,
        hashedFirstName,
        hashedLastName,
        hashedCity,
        hashedState,
        hashedZipCode,
        hashedCountry,
        extinfo
    }
}

export const generateRandomString=(length)=> {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=[]{}|;:,.<>?';
    let result = '';
  
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters[randomIndex];
    }
  
    return result;
  }